<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getProductOption"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <!-- <CAlert color="success" v-if="isSuccess === true" :show="Number(3)">
            {{ $t('savedOption') }}
          </CAlert> -->
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
              <h2 class="font-weight-normal">
                {{ $t('optional') }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2 mb-2"></HelpButton>
            </div>
            <div class="col-md-2 col-sm-3 col-5 text-right text-success" v-if="isEditData">
              <CButton id="productOptionAddButton" block color="success" style="display: flex; align-items: center; justify-content: center;" @click="addOptionButton()">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>
                {{ $t('addOption') }}
              </CButton>
            </div>
          </div>
          <hr />
          <CRow>
            <CCol sm="12" lg="12">
              <table class="table table-striped  table-bordered table-hover ">
                <thead>
                    <tr >
                        <th v-for="(item, index) in fields" :key="index" style="background-color: #eaecee;   vertical-align: middle; " :class="item._classes" >
                            {{ item.label }}  <img src="/img/delivery/grab.png" class="rounded-circle"
                            style="border-radius: 3px; width: 30px;"  v-if="item.key == 'availableStatus'"/>
                        </th>
                    </tr>
                </thead>
                <tbody class="text-dark " v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="text-center">
                      {{ item.num }}
                    </td>
                    <td style="width:35%">
                      {{ item.name }}
                      <img src="/img/delivery/grab.png" v-if="item.source === 'GRAB'" class="rounded-circle"
                        style="border-radius: 3px; width: 20px;" />
                    </td>
                    <td style="width:20%" class="text-right">{{ item.cost }}</td>
                    <td style="width:20%" class="text-right">{{ item.price }}</td>
                    <td style="width:10%"  class="text-center">
                      <CSwitch color="success"  :value="item.availableStatus" v-c-tooltip="{ content: $t('Change status') , placement: 'bottom' }"
                      v-model="item.availableStatus" :checked.sync="item.availableStatus" @update:checked="updateAnabledOption(item, index)" class="pt-2" />
                    </td>
                    <td  v-if="isEditData" style="width:10%"    class="text-center">
                      <CRow>
                          <CCol>
                            <router-link style="text-decoration:none;" :to="{
                              name: 'EditOption',
                              params: { objectId: item.objectId },
                              query: queryparams,
                            }">
                            <img src="/img/file-edit.png" style="width: 14px; height: 14px; cursor: pointer;"/>  
                            </router-link>
                          </CCol>
                          <CCol>
                            <img src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; " v-if="isDeleteData"
                              @click="confirmdelete(item)">
                          </CCol>
                        </CRow>  
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>

                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </tbody>
              </table>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getProductOption">
          </pagination>
        </CCardBody>
      </CCard>
      <CRow>
        <CModal id="productOptionDeleteModal" color="danger" :title="$t('confirmDeletOption')" :show.sync="deleteModal" centered>
          <h4 class="mt-4 mb-4 text-center">{{ $t('dialigDeleteOption') }}</h4>
          <template #footer>
            <CRow class="justify-content-around col-md-12">
              <CCol col="4">
                <CButton id="productOptionDeleteConfirmButton" v-if="loadingDeleteButton === false" @click="deleteOptionItem(itemDelete)" color="danger" block>
                  {{ $t('delete') }}
                </CButton>
                <CButton v-else-if="loadingDeleteButton === true" color="danger" block disabled>
                  <CSpinner color="white" size="sm" />
                </CButton>
              </CCol>
              <CCol col="4">
                <CButton id="productOptionDeleteCancelButton" v-if="loadingDeleteButton === false" @click="deleteModal = false" color="light" block>
                  {{ $t('cancel') }}
                </CButton>
                <CButton id="productOptionDeleteCancelLoadingButton" v-else-if="loadingDeleteButton === true" color="light" disabled block>
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
    </div>
    <div v-else>
        <access-data></access-data>
      </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import Pagination from '@/containers/Pagination'
import permis from '@/util/permission'
import Multiselect from 'vue-multiselect'
import HelpButton from '../../../containers/HelpButton.vue'
import util from '@/util/util'

export default {
  components: {
    Pagination,
    Multiselect,
    HelpButton
  },
  data() {
    return {
      namemodal: '',
      data: [],
      deleteModal: false,
      validateOptionname: '',
      loadingButton: true,
      searchLoadingButton: true,
      itemId: '',
      itemObjectId: '',
      shop: this.$store.getters.shopObjectId,
      footer: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      sequence: '',
      itemDelete: {},
      all: false,
      isEdit: false,
      isDeleteSuccess: false,
      price: 0,
      name: '',
      groupOptionName: '',
      sortdata: 'name',
      optionGroup: null,
      optionGroupName: [],
      loadingDeleteButton: false
    }
  },
  created() {
    this.getProductOption()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      return permis.findPermissionRead('product', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('product', this.$route.path)
    },
    isDeleteData() {
      return permis.findPermissionRemove('product', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    itempage() {
      return this.meta_data.current_page * 50 - 50
    },
    fields() {
      if (this.isEditData) {
        return [
          {
            key: 'num',
            label: '#',
            _style: 'width:10%',
            _classes: 'text-center text-dark font-weight-normal'
          },
          {
            key: 'name',
            label: this.$i18n.t('category'),
            _style: 'width:35%',
            _classes: 'text-left text-dark font-weight-normal'
          },
          {
            key: 'cost',
            label: this.$i18n.t('cost'),
            _classes: 'text-right text-dark font-weight-normal',
            _style: 'width:20%',
          },
          {
            key: 'price',
            label: this.$i18n.t('price'),
            _classes: 'text-right text-dark font-weight-normal',
            _style: 'width:20%',
          },
          {
            key: 'availableStatus',
            label: this.$i18n.t('enable'),
            _classes: 'text-center text-dark font-weight-normal',
            _style: 'width:10%',
          },
          {
            key: 'action',
            label: '',
            _classes: 'text-center text-dark font-weight-normal',
            _style: 'width:10%',
          },

        ]
      } else {
        return [
          {
            key: 'num',
            label: '#',
            _style: 'width:5%',
            _classes: 'text-center text-dark font-weight-normal'
          },
          {
            key: 'name',
            label: this.$i18n.t('category'),
            _style: 'width:50%',
            _classes: 'text-left text-dark font-weight-normal'
          },
          {
            key: 'cost',
            label: this.$i18n.t('cost'),
            _classes: 'text-right text-dark font-weight-normal',
            _style: 'width:15%',
          },
          {
            key: 'price',
            label: this.$i18n.t('price'),
            _classes: 'text-right text-dark font-weight-normal',
            _style: 'width:15%',
          },
          // {
          //   key: 'action',
          //   label: '',
          //   _classes: 'text-center text-dark font-weight-normal',
          //   _style: 'width:10%',
          // },

        ]
      }
    },
    items() {
      let data = this.data.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      let detail = []
      let start = this.meta_data.current_page * 50 - 50
      for (let i = 0; i < data.length; i++) {
        let name = data[i].name
        let availableStatus = false

        let grabfood = {}
        if(data[i].grabfood !== undefined){
          grabfood = data[i].grabfood 

          if(data[i].grabfood.availableStatus !== undefined){
            if (data[i].grabfood.availableStatus == 'AVAILABLE') {
              availableStatus = true
            } else {
              availableStatus = false
            }
          }
        }
        let price = '-'
        let cost = data[i].cost !== undefined && data[i].cost !== null ? data[i].cost.toFixed(2) : '-';
        if (typeof data[i].price === 'number') {
          price = data[i].price.toFixed(2)
        }
        const source = data[i].source ? data[i].source : ''

        detail.push({
          num: start + i + 1,
          name: name,
          id: data[i].id,
          price: price,
          objectId: data[i].objectId,
          cost:cost,
          availableStatus : availableStatus,
          grabfood : grabfood,
          source : source
        })
      }
      return detail
    },
  },
  methods: {
    updateAnabledOption(item, index){
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId

            let availableStatus = 'UNAVAILABLE'
            
            if (item.availableStatus ) {
                availableStatus = 'AVAILABLE'
            } else {
                availableStatus == 'UNAVAILABLE'  
            }

            const data = {
              optionItem: {
                  objectId: item.objectId,
                  id:item.id
              },
              name: item.name,
              availableStatus: availableStatus,
            }
            const headers = { shopObjectId: shopObjectId }
   
            local({
                method: 'PATCH',
                url: '/api/v3.0/grab/optionitem/update',
                data: data,
                headers: headers,
            })
            .then(
                setTimeout(() => {
                    this.loadingSaveButton = false
                }, 1000)
            ).catch((error) => {
                console.log(error)
                this.loadingSaveButton = false
            })
    },
    addOptionButton() {
      this.$router.push('option/addOption');
    },
    editOptionButtun() {
      this.$router.push({
        name: 'EditOption',
        params: { objectId: this.itemObjectId },
        query: {
          sort: this.$route.query.sort,
          page: this.$route.query.page,
          view: this.$route.view,
        },
      });
    },
    queryparams() {
      return {
        sort: this.sortdata,
        page: this.meta_data.current_page,
        view: this.showView,
        searchBy: this.searchBy,
        keyword: this.keyword,
      }
    },
    confirmdelete(item) {
      this.deleteModal = true
      this.itemDelete = item
    },
    getProductOption(page = 1) {
      this.searchLoadingButton = false
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = { page: page, limit: 50, shopObjectId: shopObjectId }
      const headers = { shopObjectId: shopObjectId }
      local({
        url: '/api/V1.0/' + uid + '/option/item/get/',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data
        
        if (res.data.paginate.items == undefined) {
          this.meta_data.items = res.data.paginate.itemCount
        } else {
          this.meta_data.items = res.data.paginate.items
        }
        if (res.data.data.deleted_at !== undefined) {
            this.data = res.data.data.splice(item => item.deleted_at === undefined);
          } else {
            this.data = res.data.data;
          }
        this.meta_data.last_page = res.data.paginate.pageCount
        this.meta_data.current_page = res.data.paginate.currentPage
        this.meta_data.itemCount = res.data.paginate.itemCount
        this.meta_data.limit = res.data.paginate.perPage
        this.countlist = res.data.length
        this.searchLoadingButton = true
        this.loadingButton = true
      })
    },
    deleteOptionItem(itemDelete) {
      this.saveLoadingButton = true
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let data = {
        id: itemDelete.id, 
        objectId: itemDelete.objectId,
        name: itemDelete.name,
        price: itemDelete.price,
        uid: uid,
        shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
        },
        deleted_at : new Date()
    };
      
        const headers = { shopObjectId: shopObjectId }


      local({
        method: 'post',
        url: '/api/V1.0/' + uid + '/option/item/update',
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.saveLoadingButton = false
            this.confirmModal = false;
            this.getProductOption()
            this.deleteModal = false;
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.form-control {
  cursor: pointer;
}

.custom-select {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #3abc98;
  background-color: #3abc98;
}

.multiselect__option--selected .multiselect__option--highlight {
  max-height: 100%;
  padding-bottom: 1px;
}

.multiselect {
  font-size: 0.875rem;
  border-color: #d8dbe0;

}

.multiselect * ::after {
  padding-left: 12px;
  padding-top: 2px;

}

.multiselect__placeholder {
  color: #768192;
  padding-left: 12px;
  padding-top: 2px;
}

.multiselect__input {
  padding-left: 12px;
  padding-top: 2px;
  font-size: 14px;

}

.multiselect__tags {
  min-height: 10px;
  display: block;
  padding-left: 1px;
  padding-top: 5px;
  padding-bottom: -2px;
  ;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
}

.multiselect__single {
  padding-left: 10px;
  padding-top: 3px;
  min-height: 10px;
  display: block;
  font-size: 14px;
  cursor: pointer;
}
</style>